@use "../abstracts" as *;

header {
   position: sticky;
   bottom: 0;
   top: 0;
   width: 100%;
   z-index: 99;
   background-color: $c-white;
   display: flex;
   justify-content: center;
   transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out;
   -webkit-transition: box-shadow 200ms ease-in-out;
   -moz-transition: box-shadow 200ms ease-in-out;
   -ms-transition: box-shadow 200ms ease-in-out;
   -o-transition: box-shadow 200ms ease-in-out;
}

.headerShadow {
   box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

nav {
   flex-direction: row;
   justify-content: center;
   max-width: $s-content-width;
   width: 100%;
   display: flex;
   color: $c-dark;
   
   ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      user-select: none;
      li {
         font-family: $f-heading;
         transition: background-color 100ms ease-in-out, color 50ms ease-in-out;
         transition: all 100ms ease-in-out;
         padding: 0.5em 1em;
         margin-block: 0.5em;
         font-weight: 800;
         text-transform: uppercase;
         text-decoration: none;
         list-style: none;
         border-block: 3px solid transparent;
         border-radius: 3px;
         cursor: pointer;
         &:hover {
            transition: background-color 100ms ease-in-out;
            background-color: $c-primary;
            color: $c-white;
            z-index: 99;
         }
      }
   }
}

@media screen and (min-width: 600px) {
   .ul-post-landing {
      li {
         padding-inline: 2.5em;
      }
   }
}


