$primary: #ff0037;
$primary-light: lighten($primary, 10%);
$primary-superlight: lighten($primary, 30%);
$primary-ultralight: lighten($primary, 50%);
$primary-dark: darken($primary, 10%);
$primary-superdark: darken($primary, 40%);

$alt: #0fcf6f;
$alt-light: lighten($alt, 5%);
$alt-ultralight: lighten($alt, 50%);
$alt-dark: darken($alt, 10%);
$alt-superdark: darken($alt, 40%);

$dark: #0a0a0a;
$dark-1: #1a1a1a;
$dark-2: #2a2a2a;
$dark-3: #333;
$dark-4: #444;
$dark-5: #777;
$dark-6: #666;
$dark-7: #777;
$dark-shadow: rgba($dark, 0.2);

$white: white;
$light: #fafafa;
$light-1: #eaeaea;