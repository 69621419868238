@use '../abstracts' as *;


* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

.App {
   display: flex;
   flex-direction: column;
   align-items: center;
}