@use '../abstracts' as *;

#landing {
   background-color: $c-white;
   height: 100vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}

#landingInner {
   color: $c-alt-superdark;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   max-width: $s-content-width--golden;
   width: 100%;
   padding: 1em;
   h1, h2, h3, h4 {
      margin: 0;
      display: inline;
   }
   h1 {
      font-family: $f-body;
      font-size: 4rem;
      font-weight: 800;
      transition: all 100ms ease-in-out;
   }
   p {
      text-align: center;
      font-weight: 400;
      span.emp {
         font-style: italic;
         font-weight: 600;
      }
   }
}

.mainHeading {
   display: flex;
   flex-direction: column;
}

#logo {
   width: 8ch;
   margin-block: 2em;
}