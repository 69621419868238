@use "../abstracts" as *;

main {
   width: 100%;
}

.darkBackground {
   background-color: $c-primary-superdark;
}

.sectionSuper {
   display: flex;
   flex-direction: column;
   align-items: center;   
   width: 100%;
}

section {
   h1, h2, h3, h4 {
      text-transform: uppercase;
   }
   p {
      text-align: justify;
   }
   width: 100%;
   max-width: $s-content-width;
   &:first-of-type {
      margin-top: 100px;
   }
   margin: 0;
   padding: 1rem 1em 10rem;
   > h1 {
      // position: sticky;
      // top: 60px;
      margin-bottom: 30px;
      margin-block: 1em 2em;
      padding-top: 30px;
      // text-align: center;
      // text-transform: uppercase;
      // font-style: italic;
   }
}


.primarySchema {
   background-color: $c-primary-superdark;
   color: $c-primary-ultralight;
   h1 {
      color: $c-primary-light;
   }
}

.primarySchema--light {
   background-color: $c-primary-ultralight;
   color: $c-primary-superdark;
   h1 {
      color: $c-primary;
   }
}

.altSchema {
   background-color: $c-alt-superdark;
   color: $c-alt-ultralight;
   h1 {
      color: $c-alt-light;
   }
}

.altSchema--light {
   background-color: $c-alt-ultralight;
   color: $c-alt-superdark;
   h1 {
      color: $c-alt;
   }
}