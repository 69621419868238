@import url("https://use.typekit.net/ruz5aai.css");

// $heading: "neue-haas-grotesk-display", serif;
// $heading: "soleil", serif;

// $heading: "neue-haas-unica", serif;
$heading: "brandon-grotesque", serif;
$body: "ff-tisa-sans-web-pro", serif;

* {
   font-family: $body;
}

:root {
   font-size: 16px;
}

// RESPONSIVE FONTS ////////////////////////////////////

@media screen and (max-width: 900px) {
   :root {
      font-size: 22px;
   }
}

@media screen and (max-width: 600px) {
   :root {
      font-size: 20px;
   }
}

@media screen and (max-width: 500px) {
   :root {
      font-size: 18px;
   }
}

@media screen and (max-width: 400px) {
   :root {
      font-size: 16px;
   }
}

@media screen and (max-width: 350px) {
   :root {
      font-size: 14px;
   }
}

@media screen and (max-width: 300px) {
   :root {
      font-size: 12px;
   }
}

@media screen and (min-width: 900px) {
   :root {
      font-size: 24px;
   }
}

p {
   margin-bottom: 1rem;
   font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
   margin: 3rem 0 1.38rem;
   font-family: $heading, serif;
   font-weight: 800;
   line-height: 1.3;
}

h1 {
   margin-top: 0;
   font-size: 3.052rem;
}

h2 {
   font-size: 2.441rem;
}

h3 {
   font-size: 1.953rem;
}

h4 {
   font-size: 1.563rem;
}

h5 {
   font-size: 1.25rem;
}

small,
.text_small {
   font-size: 0.8rem;
}