@use "../abstracts" as *;

.cardList {
   display: flex;
   flex-direction: column;
   gap: 10em;
}

.card {
   ::selection {
      background-color: rgba($c-alt, 0.4);
   }
   border-block: 1px dashed $c-light-1;
   background-color: $c-white;
   padding-block: 1em;
   display: flex;
   color: $c-dark-2;
   flex-direction: row;
   justify-content: space-between;
   gap: 2em;
   @media screen and (max-width: 850px) {
      flex-direction: column;
   }
   h3 {
      // color: $c-primary-dark;
      margin: 0;
   }
   p {
      color: $c-dark-7;
      font-weight: 400;
   }

   .card-part {
      flex: 50%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      img {
         width: 100%;
         padding: 1em;
         height: 100%;
         object-fit: contain;
         object-position: top;
         border: 1px dashed $c-light-1;
         filter: grayscale(1);
         user-select: none;
         &:hover {
            filter: none;
         }
      }
   }
}

.project__type {
   * {
      text-transform: uppercase;
      font-weight: 700;
   }
}

.project__stack {
   color: $c-dark-4;
}

.listContainer {
   ul {
      display: flex;
      gap: 1ch;
      flex-wrap: wrap;
      width: 100%;
   }
   li {
      list-style: none;
   }
   a {
      padding-inline: 1ch;
      text-decoration: none;
      font-weight: 500;
      color: $c-dark-2;
      transition: color 100ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;
      border: 1px solid $c-dark-2;
      &:hover {
         border-color: $c-alt;
         background-color: $c-alt;
         color: $c-white;
      }
   }
}

.project__links {
}

.cardHR {
   height: 1ch;
   margin-block: 0.5ch;
   width: 100%;
   background-color: $c-dark-2;
}
